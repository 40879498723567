import SideBar from "./../components/sidebar";
import Dashboard from "./../components/dashboard";

export default function Home() {
  return (
    <div>
      <SideBar comp={<Dashboard />} />
    </div>
  );
}
