import * as Yup from "yup";
import Swal from "sweetalert2";
import React, { useState } from "react";
import Form from "react-bootstrap/Form";
import draftToHtml from "draftjs-to-html";
import { useForm } from "react-hook-form";
import Modal from "react-bootstrap/Modal";
import Button from "react-bootstrap/Button";
import { Editor } from "react-draft-wysiwyg";
import { yupResolver } from "@hookform/resolvers/yup";
import "react-draft-wysiwyg/dist/react-draft-wysiwyg.css";
import CircularProgress from "@mui/material/CircularProgress";
import {
  EditorState,
  convertToRaw,
  ContentState,
  convertFromHTML,
} from "draft-js";

// import functions manages
import { modifyBilan } from "../utils/service/bilan_manage";

export default function ModalFormModifyBilan({
  showModal,
  setShowModal,
  setBilanData,
  data,
  setDataModify,
}) {
  const [posted, setPosted] = useState(false);
  const handleClose = () => setShowModal(false);
  const [contenu, setContenu] = useState("");

  const onEditorStateChange = (editorState) => {
    setEditorState(editorState);
    const contentState = editorState.getCurrentContent();
    const rawContent = convertToRaw(contentState);
    const htmlContent = draftToHtml(rawContent);
    setContenu(htmlContent);
  };

  const validationSchema = Yup.object().shape({
    titre: Yup.string().required("Titre est obligatoire"),
    tarif: Yup.string().required("Tarif est obligatoire"),
    duree: Yup.string().required("Durée est obligatoire"),
    ageCible: Yup.string().required("Age cible est obligatoire"),
  });

  const ModifyBilan = async (data_) => {
    let finalData = {
      ...data_,
      tarif: parseInt(data_.tarif),
      contenu: contenu,
      id: data?.id,
    };
    setPosted(true);
    await modifyBilan(finalData, data?.id)
      .then((response) => {
        setPosted(false);
        handleClose();
        Swal.fire({
          title: "Succès!",
          text: `${data?.titre}. ${response.data.message} `,
          icon: "success",
          confirmButtonText: "OK",
        }).then(() => {
          setBilanData((prevState) => {
            const index = prevState.findIndex((x) => x.id === data?.id);
            prevState[index] = finalData;
            return [...prevState];
          });
        });
      })
      .catch((error) => {
        setPosted(false);
        Swal.fire({
          title: "Erreur!",
          text: `${error.response.data.message}`,
          icon: "error",
          confirmButtonText: "OK",
        });
      });
  };

  const {
    register,
    handleSubmit,
    formState: { errors },
  } = useForm({
    resolver: yupResolver(validationSchema),
  });

  const [editorState, setEditorState] = useState(
    EditorState.createWithContent(
      ContentState.createFromBlockArray(
        convertFromHTML(data?.contenu).contentBlocks
      )
    )
  );

  return (
    <>
      <Modal
        show={showModal}
        onHide={handleClose}
        size="xl"
        style={{
          backgroundColor: "rgba(0,0,0,0.5)",
          margin: "50px",
        }}
        backdrop="static"
        keyboard={false}
      >
        <Modal.Header closeButton>
          <Modal.Title
            style={{
              fontSize: "1.5rem",
              fontWeight: "bold",
              color: "#444444",
              textAlign: "center",
            }}
          >
            {" "}
            Modifier un bilan{" "}
          </Modal.Title>
        </Modal.Header>

        <Modal.Body>
          <Form onSubmit={handleSubmit(ModifyBilan)}>
            <div
              style={{
                display: "flex",
                justifyContent: "space-evenly",
              }}
            >
              <Form.Group
                className="mb-3"
                controlId="exampleForm.ControlInput1"
                style={{
                  width: "49%",
                }}
              >
                <Form.Label>Titre</Form.Label>
                <Form.Control
                  type="text"
                  name="titre"
                  autoFocus
                  defaultValue={data?.titre}
                  {...register("titre")}
                />
                {errors.name && (
                  <span className="error">{errors.titre.message}</span>
                )}
              </Form.Group>
              <Form.Group
                className="mb-3"
                controlId="exampleForm.ControlTextarea1"
                style={{
                  width: "49%",
                }}
              >
                <Form.Label>Age cible</Form.Label>
                <Form.Select
                  aria-label="Default select example"
                  name="ageCible"
                  {...register("ageCible")}
                >
                  <option defaultValue={data?.ageCible} value="+45 ans">
                    +45 ans
                  </option>
                  <option defaultValue={data?.ageCible} value="-45 ans">
                    -45 ans
                  </option>
                </Form.Select>
                {errors.name && (
                  <span className="error">{errors.age.message}</span>
                )}
              </Form.Group>
            </div>
            <div
              style={{
                display: "flex",
                justifyContent: "space-evenly",
              }}
            >
              <Form.Group
                className="mb-3"
                controlId="exampleForm.ControlInput1"
                style={{
                  width: "49%",
                }}
              >
                <Form.Label>Tarif(en FCFA)</Form.Label>
                <Form.Control
                  type="number"
                  name="tarif"
                  autoFocus
                  defaultValue={data?.tarif}
                  {...register("tarif")}
                />
                {errors.name && (
                  <span className="error">{errors.tarif.message}</span>
                )}
              </Form.Group>
              <Form.Group
                className="mb-3"
                controlId="exampleForm.ControlInput1"
                style={{
                  width: "49%",
                }}
              >
                <Form.Label>Durée(Ex:2j14h30mn)</Form.Label>
                <Form.Control
                  type="text"
                  name="duree"
                  autoFocus
                  defaultValue={data?.duree}
                  {...register("duree")}
                />
                {errors.name && (
                  <span className="error">{errors.duree.message}</span>
                )}
              </Form.Group>
            </div>

            <Form.Group
              className="mb-3"
              controlId="exampleForm.ControlTextarea1"
            >
              <Form.Label>Contenu</Form.Label>
              <Editor
                editorStyle={{
                  border: "1px solid black",
                  height: "330px",
                }}
                editorState={editorState}
                onEditorStateChange={onEditorStateChange}
              />
            </Form.Group>

            <Button
              type="submit"
              variant="primary"
              style={{
                width: "100%",
                border: "none",
                color: "#fff",
                borderRadius: "50px",
              }}
            >
              {posted ? "En cours..." : "Modifier"}
            </Button>
          </Form>
        </Modal.Body>
      </Modal>
    </>
  );
}
