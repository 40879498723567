import Modal from "react-bootstrap/Modal";
import { Row, Col, Card } from "react-bootstrap";
import CircularProgress from "@mui/material/CircularProgress";
import { useFetchOneUser } from "../utils/hooks/use_fetch_one_user";

const UserProfil = ({ openModal, setOpenModal, userId }) => {
  const onHide = () => {
    setOpenModal(false);
  };
  const { user, isLoadingUser, errorUser } = useFetchOneUser(userId);

  function transformDate(element) {
    let date = new Date(element);
    let options = {
      weekday: "long",
      year: "numeric",
      month: "long",
      day: "numeric",
      hour: "numeric",
      minute: "numeric",
    };

    return date.toLocaleDateString("fr-FR", options);
  }
  return (
    <>
      {errorUser ? (
        <div>Une erreur est survenue</div>
      ) : (
        <Modal
          show={openModal}
          onHide={onHide}
          size="lg"
          style={{
            marginTop: "5rem",
          }}
          backdrop="static"
          keyboard={false}
        >
          {isLoadingUser ? (
            <div
              style={{
                display: "flex",
                justifyContent: "center",
                alignItems: "center",
                height: "100%",
                width: "100%",
              }}
            >
              <CircularProgress />
            </div>
          ) : (
            <>
              <Modal.Header closeButton>
                <Modal.Title
                  style={{
                    fontSize: "1.5rem",
                    fontWeight: "bold",
                    color: "#444444",
                    textAlign: "center",
                  }}
                >
                  Information sur cet utilisateur
                </Modal.Title>
              </Modal.Header>
              <Modal.Body>
                <Card className="mb-3" style={{ borderRadius: ".5rem" }}>
                  <Row className="g-0">
                    <Col
                      md={4}
                      className="gradient-custom text-center text-white"
                      style={{
                        borderTopLeftRadius: ".5rem",
                        borderBottomLeftRadius: ".5rem",
                        backgroundColor: "#3F51B5",
                      }}
                    >
                      <Card.Img
                        src={
                          !user?.pdp
                            ? user?.sexe === "homme"
                              ? "https://img.freepik.com/vecteurs-premium/personnage-avatar-homme-affaires_24877-18284.jpg?w=2000"
                              : "https://formationepgv.com/wp-content/uploads/2020/11/avatar-femme-brune.png"
                            : process.env.REACT_APP_BACKEND_URL +
                              "/" +
                              user?.pdp.split("/").slice(3, 5).join("/")
                        }
                        alt=""
                        className="img-fluid my-5"
                        style={{ width: "80px" }}
                      />
                      <Card.Title>
                        {user?.first_name} {user?.last_name}
                      </Card.Title>
                      <Card.Text>{user?.sexe.toUpperCase()}</Card.Text>
                      <Card.Text>{user?.birth_date}</Card.Text>
                      <i className="far fa-edit mb-5"></i>
                    </Col>
                    <Col md={8}>
                      <Card.Body className="p-4">
                        <h6>Informations</h6>
                        <hr className="mt-0 mb-4" />
                        <Row className="pt-1">
                          <Col className="mb-3" xs={6}>
                            <h6>Email</h6>
                            <p className="text-muted">{user?.email}</p>
                          </Col>
                          <Col className="mb-3" xs={6}>
                            <h6>Phone</h6>
                            <p className="text-muted">{user?.phone}</p>
                          </Col>
                        </Row>
                        <Row className="pt-1">
                          <Col className="mb-3" xs={6}>
                            <h6>Adresse</h6>
                            <p className="text-muted">{user?.address}</p>
                          </Col>
                          <Col className="mb-3" xs={6}>
                            <h6>Résidence</h6>
                            <p className="text-muted">
                              {user?.country_residence}
                            </p>
                          </Col>
                        </Row>
                        <h6>Informations supplementaires</h6>
                        <hr className="mt-0 mb-4" />
                        <Row className="pt-1">
                          <Col className="mb-3" xs={6}>
                            <h6>Crée le: </h6>
                            <p className="text-muted">
                              {transformDate(user?.created_at)}
                            </p>
                          </Col>
                          <Col className="mb-3" xs={6}>
                            <h6>Modifié le: </h6>
                            <p className="text-muted">
                              {transformDate(user?.updated_at) !==
                              "Invalid Date"
                                ? transformDate(user?.updated_at)
                                : "Pas encore modifié le profil de cet utilisateur"}
                            </p>
                          </Col>
                        </Row>
                        <h6>Photo passeport</h6>
                        <hr className="mt-0 mb-4" />
                        <Row
                          className="pt-1"
                          style={{
                            display: "flex",
                            justifyContent: "center",
                          }}
                        >
                          <Card.Img
                            src={
                              process.env.REACT_APP_BACKEND_URL +
                              "/" +
                              user?.passport.split("/").slice(3, 5).join("/")
                            }
                            alt="Pas photo du passeport"
                            className="img-fluid my-5"
                            style={{
                              width: "40%",
                            }}
                          />
                        </Row>
                      </Card.Body>
                    </Col>
                  </Row>
                </Card>
              </Modal.Body>
            </>
          )}
        </Modal>
      )}
    </>
  );
};

export default UserProfil;
