const { RouteAxios } = require("../url_axios");

function AddManyOrOneChoixConsultationt(datas) {
  return new Promise((resolve, reject) => {
    for (let i = 0; i < datas.length; i++) {
      console.log("data_insert", datas[i]);
      RouteAxios.post(
        "/choix_consultation/",
        {
          nom: datas[i],
        },
        {
          headers: {
            "Content-Type": "application/json",
            Authorization: `Bearer ${localStorage.getItem("token")}`,
          },
        }
      );
    }
    resolve();
  });
}

function modifyChoixConsultation(data) {
  return RouteAxios.put(
    "/choix_consultation/" + data.id,
    {
      nom: data.nom,
    },
    {
      headers: {
        "Content-Type": "application/json",
        Authorization: `Bearer ${localStorage.getItem("token")}`,
      },
    }
  );
}

function deleteChoixConsultation(id) {
  return RouteAxios.delete(`/choix_consultation/${id}`, {
    headers: {
      "Content-Type": "application/json",
      Authorization: `Bearer ${localStorage.getItem("token")}`,
    },
  });
}

export {
  modifyChoixConsultation,
  deleteChoixConsultation,
  AddManyOrOneChoixConsultationt,
};
