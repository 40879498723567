import "react-toastify/dist/ReactToastify.css";
import { ToastContainer } from "react-toastify";

const Toastr = () => {
  return (
    <ToastContainer
      position="top-center"
      autoClose={3000}
      hideProgressBar
      newestOnTop={false}
      closeOnClick
      rtl={false}
      pauseOnFocusLoss
      draggable
      pauseOnHover
      theme="colored"
      style={{ fontSize: "14.5px" }}
    />
  );
};

export default Toastr;
