import Swal from "sweetalert2";
import React, { useMemo, useState } from "react";
import MaterialReactTable from "material-react-table";
import { transformFullDate } from "../utils/service/extras";
import { UpdateReservationBilan } from "../utils/service/reservation_bilan";
import { useFetchReservationBilan } from "../utils/hooks/use_fetch_reservation_bilan";

// import Component
import Modal from "./modal";

//Material-UI Imports

import { Button } from "@mui/material";

const Payment = () => {
  const [openModal, setOpenModal] = useState(false);
  const [modalData, setModalData] = useState("");

  let data = [];
  const {
    dataReservationBilan,
    isLoadingReservationBilan,
    errorReservationBilan,
  } = useFetchReservationBilan();

  if (
    !isLoadingReservationBilan &&
    !errorReservationBilan &&
    dataReservationBilan
  ) {
    data = dataReservationBilan.filter(
      (reservation) => reservation.cancel === true
    );
  }

  //should be memoized or stable
  const columns = useMemo(
    () => [
      {
        accessorKey: "id",
        header: "Numéro de la réservation",
      },
      {
        accessorFn: (row) => transformFullDate(row.created_at),
        header: "Date de post",
      },
      {
        accessorFn: (row) => row.bilan.nameBilanReserve,
        header: "Bilan réservé",
      },
      {
        accessorFn: (row) => {
          if (row.bilan.price) {
            return row.bilan.price + " FCFA";
          }
          return "0 FCFA";
        },
        header: "Prix du bilan",
      },
      {
        accessorFn: (row) => transformFullDate(row.cancel_at),
        header: "Annulé lé",
      },
      {
        accessorFn: (row) => {
          if (row.remboursement) {
            if (row.remboursement.rembourse) {
              return "Oui";
            }
            return "Non";
          }

          return "Non";
        },
        header: "Statut du remboursement",
        Cell: ({ cell }) => (
          <div
            style={{
              color: cell.getValue() === "Oui" ? "green" : "red",
              fontWeight: "bold",
              justifyContent: "center",
            }}
          >
            {cell.getValue()}
          </div>
        ),
      },

      {
        accessorFn: (row) => {
          if (row.remboursement) {
            if (row.remboursement.rembourse) {
              return transformFullDate(row.remboursement.rembourse_at);
            }
            return "";
          }

          return "";
        },
        header: "Remboursé le",
      },
    ],
    []
  );

  return (
    <>
      <h1
        style={{
          textAlign: "center",
          marginTop: "2rem",
          marginBottom: "2rem",
          fontFamily: "Roboto",
        }}
      >
        {" "}
        Histoire des réservations annulées et son payment
      </h1>
      <MaterialReactTable
        columns={columns}
        data={data ?? []}
        state={{ isLoading: isLoadingReservationBilan }}
        enableRowSelection
        enableRowActions
        positionActionsColumn="last"
        renderRowActions={({ row }) => (
          <div
            style={{
              display: "flex",
              justifyContent: "space-around",
            }}
          >
            <Button
              style={{ marginRight: "1rem", borderRadius: "50px" }}
              variant="contained"
              onClick={() => {
                setOpenModal(true);
                setModalData(row.original);
              }}
            >
              Voir Details
            </Button>

            <Button
              style={{ marginRight: "1rem", borderRadius: "50px" }}
              variant="contained"
              disabled={
                row.original.remboursement
                  ? row.original.remboursement.rembourse
                    ? true
                    : false
                  : false
              }
              onClick={() => {
                Swal.fire({
                  title: `Êtes-vous sûr ?`,
                  text: `remboursement de la réservation n° ${row.original.id}`,
                  icon: "warning",
                  showCancelButton: true,
                  confirmButtonText: "Oui, rembourser!",
                  cancelButtonText: "Non, annuler!",
                  reverseButtons: true,
                }).then(async (result) => {
                  if (result.isConfirmed) {
                    Swal.showLoading();
                    await UpdateReservationBilan(
                      {
                        remboursement: {
                          rembourse: true,
                          rembourse_at: new Date().toISOString(),
                        },
                      },
                      row.original.id
                    )
                      .then((result) => {
                        Swal.fire(
                          "Remboursé!",
                          `La réservation n° ${row.original.id} est remboursé.`,
                          "success"
                        ).then(() => {
                          window.location.reload();
                        });
                      })
                      .catch((error) => {
                        Swal.fire(
                          "Erreur!",
                          `La réservation n° ${row.original.id} n'est pas remboursé.`,
                          "error"
                        );
                      });
                  } else if (result.dismiss === Swal.DismissReason.cancel) {
                    Swal.fire(
                      "Annulé",
                      `La réservation n° ${row.original.id} n'est pas remboursé.`,
                      "error"
                    );
                  }
                });
              }}
            >
              Rembourser
            </Button>
          </div>
        )}
      />
      {openModal && (
        <Modal
          open={openModal}
          setOpenModal={setOpenModal}
          modalData={modalData}
        />
      )}
    </>
  );
};

export default Payment;
