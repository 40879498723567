import { Box, Button } from "@mui/material";
import React, { useMemo, useState } from "react";
import MaterialReactTable from "material-react-table";
import { transformDate, transformFullDate } from "../utils/service/extras";
import ApprouverConsultationMedical from "./modal_approuver_consultation_medical";
import { useFetchReservationMedical } from "../utils/hooks/use_fetch_reservation_medical";

// import Component
import UserProfil from "./modal_affichage_user_reservation_medical";

function convertArrayToString(element) {
  if (!element) {
    return "";
  }
  return element.join(", ");
}

function AnnuleConfirme(element) {
  if (element.cancel === true) {
    return `Annulé__le ${transformDate(element.cancel_at)}`;
  }
  if (element.confirm === true) {
    return `Confirmé__le ${transformDate(element.confirm_at)}`;
  }
  return "En attente";
}

function medicin(element) {
  if (element === "" || element === undefined || element === null) {
    return "";
  }
  return element;
}

function choixDate(dates) {
  if (!dates) {
    return [];
  }
  return dates.map((date) => transformDate(date));
}

const ReservationMedical = () => {
  const [openModal, setOpenModal] = useState(false);
  const [userId, setUserId] = useState();

  const [openModalApprouver, setOpenModalApprouver] = useState(false);
  const [modalDataApprouver, setModalDataApprouver] = useState(null);

  const { dataReservationMedical, isLoadingReservationMedical } =
    useFetchReservationMedical();

  //should be memoized or stable
  const columns = useMemo(
    () => [
      {
        accessorKey: "id",
        header: "Numéro de la réservation",
      },
      {
        accessorFn: (row) => transformFullDate(row.created_at),
        header: "Date de post",
      },
      {
        accessorFn: (row) => convertArrayToString(row.choix_consultations),
        header: "Choix de consultation",
      },
      {
        accessorFn: (row) => choixDate(row.dates),
        header: "Choix de date du propriétaire",
        Cell: ({ cell }) => (
          <ul>
            {cell.getValue().map((date, index) => (
              <li key={index}>{date}</li>
            ))}
          </ul>
        ),
      },
      {
        accessorFn: (row) => medicin(row.nom_medecin),
        header: "Choix de medecin",
      },
      {
        accessorFn: (row) =>
          AnnuleConfirme({
            cancel: row.cancel,
            cancel_at: row.cancel_at,
            confirm: row.confirm,
            confirm_at: row.confirm_at,
          }),
        header: "status",
        Cell: ({ cell }) => (
          <>
            <Box
              component="span"
              sx={(theme) => ({
                backgroundColor:
                  cell.getValue().split("__")[0] === "Annulé"
                    ? theme.palette.error.dark
                    : cell.getValue().split("__")[0] === "Confirmé"
                    ? theme.palette.success.dark
                    : theme.palette.warning.dark,

                borderRadius: "0.25rem",
                color: "#fff",
                maxWidth: "9ch",
                p: "0.25rem",
              })}
            >
              {cell.getValue().split("__")[0] === "Annulé"
                ? cell.getValue().split("__")[0] +
                  " " +
                  cell.getValue().split("__")[1]
                : cell.getValue().split("__")[0] === "Confirmé"
                ? cell.getValue().split("__")[0] +
                  " " +
                  cell.getValue().split("__")[1]
                : cell.getValue()}
            </Box>
          </>
        ),
      },
      {
        accessorFn: (row) => {
          if (row.confirm === true) {
            return transformDate(row.date_confirm);
          } else {
            return "";
          }
        },
        header: "Date du rdv confirmé",
      },
    ],
    []
  );

  return (
    <>
      <h1
        style={{
          textAlign: "center",
          marginTop: "2rem",
          marginBottom: "2rem",
          fontFamily: "Roboto",
        }}
      >
        {" "}
        Liste de la réservation Médical
      </h1>
      <MaterialReactTable
        columns={columns}
        data={
          !isLoadingReservationMedical && dataReservationMedical
            ? dataReservationMedical
            : []
        }
        state={{ isLoading: isLoadingReservationMedical }}
        enableRowSelection
        enableRowActions
        positionActionsColumn="last"
        renderRowActions={({ row }) => (
          <div
            style={{
              display: "flex",
              justifyContent: "space-around",
              alignItems: "center",
            }}
          >
            <Button
              style={{ marginRight: "1rem", borderRadius: "50px" }}
              variant="contained"
              onClick={() => {
                setUserId(row.original.userId);
                setOpenModal(true);
              }}
            >
              Voir propriétaire
            </Button>
            <Button
              style={{ marginRight: "1rem", borderRadius: "50px" }}
              variant="contained"
              color="success"
              disabled={
                true
                  ? row.original.confirm === true || row.original.cancel
                  : false
              }
              onClick={() => {
                setModalDataApprouver(row.original);
                setOpenModalApprouver(true);
              }}
            >
              Approuver
            </Button>
          </div>
        )}
      />
      {openModal && (
        <UserProfil
          openModal={openModal}
          setOpenModal={setOpenModal}
          userId={userId}
        />
      )}

      {openModalApprouver && (
        <ApprouverConsultationMedical
          showModal={openModalApprouver}
          setShowModal={setOpenModalApprouver}
          modalData={modalDataApprouver}
        />
      )}
    </>
  );
};

export default ReservationMedical;
