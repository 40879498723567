import React from "react";
import Login from "../components/login";
import { Paper, makeStyles } from "@material-ui/core";

const useStyles = makeStyles((theme) => ({
  paper: {
    padding: theme.spacing(3),
    backgroundColor: "#F7F7F7",
    borderRadius: "12px",
    boxShadow: "0px 3px 10px rgba(0, 0, 0, 0.2)",
    width: "40%",
    margin: "auto",
    textAlign: "center",
    marginTop: "200px",
  },
}));

const login = () => {
  // eslint-disable-next-line react-hooks/rules-of-hooks
  const classes = useStyles();
  return (
    <Paper className={classes.paper}>
      <Login />
    </Paper>
  );
};

export default login;
