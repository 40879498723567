import { useEffect, useMemo, useState } from "react";
import { useFetchMessages } from "./../utils/hooks/fetch_all_message";
import { transformDate } from "../utils/service/extras";
import Swal from "sweetalert2";
import { Button } from "@mui/material";
import MaterialReactTable from "material-react-table";
import AddCode from "./add_code";
import AddMessage from "./add_message";

export const ListMessage = () => {
  const [showModal, setShowModal] = useState(false);
  const [data, setData] = useState();
  const { dataMessages, isLoadingMessage, errorMessages } = useFetchMessages();
  const [posted, setPosted] = useState(false);
  const [isDeleted, setIsDeleted] = useState(false);

  useEffect(() => {
    if (!isLoadingMessage && !errorMessages && dataMessages) {
      setData(dataMessages);
    }
  }, [dataMessages, isLoadingMessage, errorMessages]);

  const columns = useMemo(
    () => [
      {
        accessorKey: "id",
        header: "id",
        enableClickToCopy: true,
      },
      {
        accessorFn: (row) => row.titre,
        header: "Titre",
      },
      {
        accessorFn: (row) => row.contenu,
        header: "Contenu",
      },
      {
        accessorFn: (row) => row.topic,
        header: "Topic",
      },
      {
        accessorFn: (row) => transformDate(row.created_at),
        header: "Ajouté le",
      },
    ],
    []
  );
  return (
    <>
      <h1
        style={{
          textAlign: "center",
          marginTop: "2rem",
          marginBottom: "2rem",
          fontFamily: "Roboto",
        }}
      >
        {" "}
        Liste du message
      </h1>

      <div
        style={{
          display: "flex",
          justifyContent: "flex-end",
          marginBottom: "1rem",
        }}
      >
        <Button
          style={{ marginRight: "1rem", borderRadius: "50px" }}
          variant="contained"
          color="primary"
          onClick={() => {
            setShowModal(true);
          }}
        >
          {posted ? "En cours..." : "Envoyer un message"}
        </Button>
      </div>

      <MaterialReactTable
        columns={columns}
        data={data ?? []}
        state={{ isLoading: isLoadingMessage }}
        enableRowSelection
        enableRowActions
        positionActionsColumn="last"
        renderRowActions={({ row }) => (
          <div>
            <Button
              style={{ marginRight: "1rem", borderRadius: "50px" }}
              variant="contained"
              color="error"
              onClick={() => {
                Swal.fire({
                  title: "Etes-vous sûr?",
                  text: `Voulez-vous vraiment supprimer le code ${row.original.code} ?`,
                  icon: "warning",
                  showCancelButton: true,
                  confirmButtonColor: "#3085d6",
                  cancelButtonColor: "#d33",
                  confirmButtonText: "Oui, supprimez-le!",
                  cancelButtonText: "Non, Annulez-le!",
                }).then(async (result) => {
                  if (result.isConfirmed) {
                  }
                });
              }}
            >
              {isDeleted ? "Suppression..." : "Supprimer"}
            </Button>
          </div>
        )}
      />
      {showModal && (
        <AddMessage
          setShowModal={() => setShowModal(false)}
          showModal={showModal}
        />
      )}
    </>
  );
};
