import { CSVLink } from "react-csv";
import { Box, Button } from "@mui/material";
import ModalPassport from "./modal_passport";
import MaterialReactTable from "material-react-table";
import React, { useMemo, useState, useEffect } from "react";
import { getAllUserConfidence } from "../utils/service/user_manage";

function ListUser() {
  const [data, setData] = useState([]);
  const [isLoading, setIsLoading] = useState(false);
  const [showModal, setShowModal] = useState(false);
  const [dataModal, setDataModal] = useState({});

  // Csv export function
  const headers = [
    { label: "id", key: "id" },
    { label: "Nom", key: "first_name" },
    { label: "Prénom", key: "last_name" },
    { label: "Sexe", key: "sexe" },
    { label: "Date de naissance", key: "birth_date" },
    { label: "Adresse", key: "address" },
    { label: "Pays de résidence", key: "country_residence" },
    { label: "Email", key: "email" },
    { label: "Téléphone", key: "phone" },
    { label: "Passeport", key: "passport" },
    { label: "PDP", key: "pdp" },
  ];

  useEffect(() => {
    setIsLoading(true);
    getAllUserConfidence()
      .then((res) => {
        setData(res.data.users);
      })
      .finally(() => setIsLoading(false));
  }, []);

  const dataCsv = data.map((user) => {
    return {
      id: user.id,
      first_name: user.first_name,
      last_name: user.last_name,
      sexe: user.sexe,
      birth_date: user.birth_date,
      address: user.address,
      country_residence: user.country_residence,
      email: user.email,
      phone: user.phone,
      passport:
        process.env.REACT_APP_BACKEND_URL +
        "/" +
        user.passport.split("/").slice(3, 5).join("/"),
      pdp:
        user.pdp !== null
          ? process.env.REACT_APP_BACKEND_URL +
            "/" +
            user.pdp.split("/").slice(3, 5).join("/")
          : null,
    };
  });

  const columns = useMemo(
    () => [
      {
        accessorFn: (row) =>
          (row.pdp
            ? process.env.REACT_APP_BACKEND_URL +
              "/" +
              row.pdp.split("/").slice(3, 5).join("/")
            : row.sexe === "homme"
            ? "https://img.freepik.com/vecteurs-premium/personnage-avatar-homme-affaires_24877-18284.jpg?w=2000"
            : "https://formationepgv.com/wp-content/uploads/2020/11/avatar-femme-brune.png") +
          " " +
          row.birth_date +
          " " +
          row.id,
        header: "identité",
        Cell: ({ cell }) => (
          console.log(cell.getValue()),
          (
            <div
              style={{
                display: "flex",
                flexDirection: "line",
                textAlign: "center",
                margin: "0.5rem",
              }}
            >
              <img
                src={cell.getValue().split(" ")[0]}
                alt=""
                style={{
                  width: "80px",
                  height: "80px",
                  borderRadius: "50%",
                  objectFit: "cover",
                  marginRight: "1rem",
                }}
              />

              <p
                style={{
                  margin: "0.5rem",
                  textAlign: "center",
                  marginTop: "15px",
                }}
              >
                <span
                  style={{
                    color: "red",
                    fontWeight: "bold",
                    fontSize: "1.2rem",
                  }}
                >
                  {cell.getValue().split(" ")[1]}
                </span>{" "}
                <br />
                <span
                  style={{
                    color: "#3F51B5",
                    fontWeight: "bold",
                    fontSize: "1.2rem",
                    marginTop: "1rem",
                  }}
                >
                  {cell.getValue().split(" ")[2]}
                </span>
              </p>
            </div>
          )
        ),
      },
      {
        accessorFn: (row) => row.first_name + " " + row.last_name,
        header: "Nom complet",
      },
      {
        accessorKey: "sexe",
        header: "Sexe",
      },
      {
        accessorFn: (row) => row.address + " " + row.country_residence,
        header: "Adresse",
      },
      {
        accessorFn: (row) => row.email + " " + row.phone,

        header: "Contact",
        Cell: ({ cell }) => (
          <div
            style={{
              display: "flex",
              flexDirection: "column",
              textAlign: "center",
              margin: "0.5rem",
            }}
          >
            <Box
              component="span"
              sx={(theme) => ({
                backgroundColor: theme.palette.success.dark,
                borderRadius: "50px",
                color: "#fff",
                maxWidth: "100%",
                height: "2rem",
              })}
            >
              {cell.getValue().split(" ")[0]}
            </Box>
            <Box
              component="span"
              sx={(theme) => ({
                backgroundColor: theme.palette.error.dark,
                borderRadius: "50px",
                color: "#fff",
                maxWidth: "100%",
                height: "2rem",
              })}
              style={{ marginTop: "0.5rem" }}
            >
              {cell.getValue().split(" ")[1]}
            </Box>
          </div>
        ),
      },
    ],
    []
  );

  console.log("dataModal", dataModal);
  return (
    <>
      <h1
        style={{
          textAlign: "center",
          marginTop: "2rem",
          marginBottom: "2rem",
          fontFamily: "Roboto",
        }}
      >
        {" "}
        Liste des utilisateurs
      </h1>
      <div
        style={{
          display: "flex",
          flexDirection: "row",
          justifyContent: "flex-end",
          alignItems: "center",
          textAlign: "center",
          margin: "0.5rem",
          width: "100%",
        }}
      >
        <Button
          style={{
            marginRight: "1rem",
            borderRadius: "50px",
          }}
          variant="contained"
        >
          <CSVLink
            data={dataCsv}
            headers={headers}
            filename="liste_utilisateurs_confidence.csv"
            style={{
              textDecoration: "none",
              color: "#fff",
            }}
          >
            {" "}
            Télécharger la liste des utilisateurs en csv{" "}
          </CSVLink>
        </Button>
      </div>
      <MaterialReactTable
        style={{
          width: "100%",
          margin: "0 auto",
          fontFamily: "Roboto",
          fontSize: "1.2rem",
        }}
        columns={columns}
        data={data ?? []}
        state={{ isLoading: isLoading }}
        enableRowSelection
        enableRowActions
        positionActionsColumn="last"
        renderRowActions={({ row }) => (
          console.log("row", row.original),
          (
            <div
              style={{
                display: "flex",
                flexDirection: "row",
                justifyContent: "center",
                alignItems: "center",
                textAlign: "center",
                margin: "0.5rem",
              }}
            >
              <Button
                style={{ marginRight: "1rem", borderRadius: "50px" }}
                variant="contained"
                onClick={() => {
                  window.location.href = "mailto:" + row.original.email;
                }}
              >
                Envoyer un Mail
              </Button>
              <Button
                style={{ marginRight: "1rem", borderRadius: "50px" }}
                variant="contained"
                onClick={() => {
                  setDataModal({
                    fullName:
                      row.original.first_name + " " + row.original.last_name,
                    passport:
                      process.env.REACT_APP_BACKEND_URL +
                      "/" +
                      row.original.passport.split("/").slice(3, 5).join("/"),
                  });
                  setShowModal(true);
                }}
              >
                Voir passeport
              </Button>
            </div>
          )
        )}
      />
      {showModal && (
        <ModalPassport
          data={dataModal}
          showModal={showModal}
          setShowModal={setShowModal}
        />
      )}
    </>
  );
}

export default ListUser;
