import Swal from "sweetalert2";
import { v4 as uuidv4 } from "uuid";
import React, { useState } from "react";
import Form from "react-bootstrap/Form";
import Modal from "react-bootstrap/Modal";
import Button from "react-bootstrap/Button";
import "react-toastify/dist/ReactToastify.css";
import TextField from "@mui/material/TextField";
import { addCode } from "../utils/service/code_manage";
import IconButton from "@material-ui/core/IconButton";
import AddCircleRoundedIcon from "@mui/icons-material/AddCircleRounded";
import RemoveCircleSharpIcon from "@mui/icons-material/RemoveCircleSharp";

export default function AddCode({ showModal, setShowModal }) {
  const onHide = () => setShowModal(false);
  const [posted, setPosted] = useState(false);
  const [inputFields, setInputFields] = useState([
    {
      id: uuidv4(),
      email: "",
    },
  ]);

  const handleSubmit = (e) => {
    e.preventDefault();
    setPosted(true);
    console.log("InputFields", inputFields);
    addCode(inputFields).then((res) => {
      setPosted(false);
      onHide();
      Swal.fire({
        title: "Succès!",
        text: `${res.data.message}`,
        icon: "success",
        confirmButtonText: "OK",
      }).then(() => {
        window.location.reload();
      });
    });
  };

  const handleChangeInput = (id, event) => {
    const newInputFields = inputFields.map((i) => {
      if (id === i.id) {
        i[event.target.name] = event.target.value;
      }
      return i;
    });
    setInputFields(newInputFields);
  };

  const handleAddFields = () => {
    setInputFields([
      ...inputFields,
      {
        id: uuidv4(),
        email: "",
      },
    ]);
  };

  const handleRemoveFields = (id) => {
    const values = [...inputFields];
    values.splice(
      values.findIndex((value) => value.id === id),
      1
    );
    setInputFields(values);
  };

  return (
    <Modal
      show={showModal}
      onHide={onHide}
      size="lg"
      style={{
        backgroundColor: "rgba(0,0,0,0.5)",
        margin: "50px",
      }}
      backdrop="static"
      keyboard={false}
    >
      <Modal.Header closeButton>
        <Modal.Title
          style={{
            fontSize: "1.5rem",
            fontWeight: "bold",
            color: "#444444",
            textAlign: "center",
          }}
        >
          {" "}
          Générer des codes
        </Modal.Title>
      </Modal.Header>
      <Modal.Body>
        <Form onSubmit={handleSubmit}>
          {inputFields.map((inputField) => (
            <div
              key={inputField.id}
              style={{
                display: "flex",
                marginBottom: "10px",
              }}
            >
              <TextField
                fullWidth
                required
                name="email"
                label="Email"
                variant="outlined"
                value={inputField.email}
                onChange={(event) => handleChangeInput(inputField.id, event)}
              />

              <IconButton
                disabled={inputFields.length === 1}
                onClick={() => handleRemoveFields(inputField.id)}
              >
                <RemoveCircleSharpIcon color="error" />
              </IconButton>
              <IconButton onClick={handleAddFields}>
                <AddCircleRoundedIcon color="success" />
              </IconButton>
            </div>
          ))}
          <Modal.Footer>
            <Button
              variant="danger"
              onClick={onHide}
              style={{
                borderRadius: "50px",
              }}
            >
              Fermer
            </Button>
            <Button
              type="submit"
              variant="success"
              style={{
                borderRadius: "50px",
              }}
            >
              {posted ? "En cours..." : "Générer"}
            </Button>
          </Modal.Footer>
        </Form>
      </Modal.Body>
    </Modal>
  );
}
