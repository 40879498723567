import { RouteAxios } from "../url_axios";

function getStatistique() {
  return RouteAxios.get("/statistique", {
    headers: {
      "Content-Type": "application/json",
      Authorization: `Bearer ${localStorage.getItem("token")}`,
    },
  });
}

export { getStatistique };
