import { RouteAxios } from "../url_axios";
import { useState, useEffect } from "react";

export function useFetchReservationBilan() {
  const [dataReservationBilan, setDataReservationBilan] = useState();
  const [isLoadingReservationBilan, setIsLoadingReservationBilan] =
    useState(false);
  const [errorReservationBilan, setErrorReservationBilan] = useState(false);

  useEffect(() => {
    async function FetchReservationBilan() {
      setIsLoadingReservationBilan(true);
      try {
        const response = await RouteAxios.get(`/bilan/reservation`, {
          headers: {
            "Content-Type": "application/json",
            Authorization: `Bearer ${localStorage.getItem("token")}`,
          },
        });
        console.log(response.data.data);
        setDataReservationBilan(response.data.data);
        setIsLoadingReservationBilan(false);
      } catch (e) {
        setErrorReservationBilan(true);
        console.error(e);
      } finally {
        setIsLoadingReservationBilan(false);
      }
    }
    FetchReservationBilan();
  }, []);
  return {
    dataReservationBilan,
    isLoadingReservationBilan,
    errorReservationBilan,
  };
}
