import Swal from "sweetalert2";
import Table from "react-bootstrap/Table";
import Button from "react-bootstrap/Button";
import EditIcon from "@material-ui/icons/Edit";
import ModalFormAddBilan from "./modal_add_bilan";
import React, { useState, useEffect } from "react";
import ModalFormModifyBilan from "./modal_modify_bilan";
import { deleteBilan } from "../utils/service/bilan_manage";
import CircularProgress from "@mui/material/CircularProgress";
import { useFetchBilan } from "../utils/hooks/fetch_all_bilan";
import DeleteForeverIcon from "@material-ui/icons/DeleteForever";

function Bilan() {
  const [bilanData, setBilanData] = useState([]);
  const [showModal, setShowModal] = useState(false);
  const { dataBilan, isLoadingBilan } = useFetchBilan();
  const [modifySuccess, setModifySuccess] = useState(false);

  const [showModalModify, setShowModalModify] = useState(false);
  const [dataModify, setDataModify] = useState();

  const [loading, setLoading] = useState(Array(bilanData.length).fill(false));

  useEffect(() => {
    if (dataBilan) {
      setBilanData(dataBilan);
    }
  }, [dataBilan]);

  return (
    <>
      <h1
        style={{
          textAlign: "center",
          marginTop: "2rem",
          marginBottom: "2rem",
          fontFamily: "Roboto",
        }}
      >
        Liste du Bilan Médical
      </h1>
      {!isLoadingBilan && dataBilan ? (
        <>
          <div
            style={{
              display: "flex",
              justifyContent: "flex-end",
              marginBottom: "10px",
            }}
          >
            <Button
              variant="primary"
              size="lg"
              block
              style={{
                marginBottom: "5px",
                borderRadius: "50px",
              }}
              onClick={() => setShowModal(true)}
            >
              Ajouter un bilan
            </Button>
          </div>

          <Table
            striped
            bordered
            hover
            style={{
              width: "100%",
              margin: "auto",
              border: "5px solid #dee2e6",
            }}
          >
            <thead>
              <tr
                style={{
                  backgroundColor: "#dee2e6",
                  fontSize: "1rem",
                  fontFamily: "Roboto",
                }}
              >
                <th>Titre</th>
                <th
                  style={{
                    width: "10%",
                  }}
                >
                  Age cible
                </th>
                <th>Tarif(FCFA)</th>
                <th>Durée</th>
                <th>contenu</th>
                <th>Actions</th>
              </tr>
            </thead>
            {bilanData.length > 0 ? (
              <tbody>
                {bilanData.map((bilan, index) => (
                  <tr key={index + 1}>
                    <td>{bilan.titre}</td>
                    <td>{bilan.ageCible} ans</td>
                    <td>{bilan.tarif}</td>
                    <td>{bilan.duree}</td>
                    <td>
                      <div
                        dangerouslySetInnerHTML={{
                          __html: bilan.contenu,
                        }}
                      />
                    </td>
                    <td
                      style={{
                        display: "flex",
                        justifyContent: "space-between",
                      }}
                    >
                      <Button
                        style={{
                          borderRadius: "50px",
                        }}
                        s
                        variant="primary"
                        onClick={() => {
                          setDataModify(bilan);
                          setShowModalModify(true);
                        }}
                      >
                        <EditIcon />
                      </Button>
                      <Button
                        style={{
                          borderRadius: "50px",
                        }}
                        variant="danger"
                        onClick={() => {
                          Swal.fire({
                            title: "Etes-vous sûr?",
                            text: `Voulez-vous vraiment supprimer le bilan ${bilan.titre} ?`,
                            icon: "warning",
                            showCancelButton: true,
                            confirmButtonColor: "#3085d6",
                            cancelButtonColor: "#d33",
                            confirmButtonText: "Oui, supprimez-le!",
                            cancelButtonText: "Non, Annulez-le!",
                          })
                            .then((result) => {
                              if (result.isConfirmed) {
                                setLoading((prevLoading) => {
                                  prevLoading[index] = true;
                                  return [...prevLoading];
                                });
                                deleteBilan(bilan.id)
                                  .then((result) => {
                                    if (result.status === 200) {
                                      Swal.fire(
                                        "Supprimé!",
                                        `Le bilan ${bilan.titre} a été supprimé avec succès`,
                                        "success"
                                      );
                                      setBilanData(
                                        bilanData.filter(
                                          (item) => item.id !== bilan.id
                                        )
                                      );
                                    }
                                  })
                                  .catch((err) => {
                                    Swal.fire({
                                      icon: "error",
                                      title: "Oops...",
                                      text: `Une erreur est survenue! ${err.response.data.error}}`,
                                    });
                                  })
                                  .finally(() => {
                                    setLoading((prevLoading) => {
                                      prevLoading[index] = false;
                                      return [...prevLoading];
                                    });
                                  });
                              }
                            })
                            .catch((err) => {
                              Swal.fire({
                                icon: "error",
                                title: "Oops...",
                                text: `Une erreur est survenue! ${err.response.data.error}}`,
                              });
                            });
                        }}
                      >
                        {!loading[index] ? (
                          <DeleteForeverIcon />
                        ) : (
                          <CircularProgress
                            size={20}
                            style={{
                              color: "white",
                            }}
                          />
                        )}
                      </Button>
                    </td>
                  </tr>
                ))}
              </tbody>
            ) : (
              <tbody>
                <tr>
                  <td colSpan={6} style={{ textAlign: "center" }}>
                    Aucun bilan médical n'a été ajouté
                  </td>
                </tr>
              </tbody>
            )}
          </Table>
        </>
      ) : (
        <div
          style={{
            display: "flex",
            justifyContent: "center",
            alignItems: "center",
            height: "50vh",
          }}
        >
          <CircularProgress size={100} />
        </div>
      )}
      {showModal && (
        <ModalFormAddBilan
          showModal={showModal}
          setShowModal={setShowModal}
          setBilanData={setBilanData}
        />
      )}
      {showModalModify && (
        <ModalFormModifyBilan
          showModal={showModalModify}
          setShowModal={setShowModalModify}
          setBilanData={setBilanData}
          data={dataModify}
        />
      )}
    </>
  );
}
export default Bilan;
