import Swal from "sweetalert2";
import AddCode from "./add_code";
import { CSVLink } from "react-csv";
import { Button } from "@mui/material";
import MaterialReactTable from "material-react-table";
import { deleteCode } from "../utils/service/code_manage";
import React, { useMemo, useState, useEffect } from "react";
import { useFetchAllCode } from "../utils/hooks/fetch_all_code";
import { generateCode } from "../utils/service/code_manage";
function transformDate(element) {
  let date = new Date(element);
  let options = {
    weekday: "long",
    year: "numeric",
    month: "long",
    day: "numeric",
    hour: "numeric",
    minute: "numeric",
  };

  return date.toLocaleDateString("fr-FR", options);
}

const ListCode = () => {
  const [showModal, setShowModal] = useState(false);
  const [data, setData] = useState();
  const { code, isLoadingCode, errorCode } = useFetchAllCode();
  const [posted, setPosted] = useState(false);
  const [isDeleted, setIsDeleted] = useState(false);

  useEffect(() => {
    if (!isLoadingCode && !errorCode && code) {
      setData(code);
    }
  }, [code, isLoadingCode, errorCode]);

  const headers = [
    { label: "id", key: "id" },
    { label: "Date de création", key: "created_at" },
    { label: "Code", key: "code" }
  ];

  //should be memoized or stable
  const columns = useMemo(
    () => [
      {
        accessorKey: "id",
        header: "id",
        enableClickToCopy: true,
      },
      {
        accessorFn: (row) => transformDate(row.created_at),
        header: "Ajouté le",
      },
      { accessorKey: "code", header: "Code", enableClickToCopy: true },
    
    ],
    []
  );

  return (
    <>
      <h1
        style={{
          textAlign: "center",
          marginTop: "2rem",
          marginBottom: "2rem",
          fontFamily: "Roboto",
        }}
      >
        {" "}
        Liste du Code
      </h1>

      <div
        style={{
          display: "flex",
          justifyContent: "flex-end",
          marginBottom: "1rem",
        }}
      >
        
        <Button
              style={{ marginRight: "1rem", borderRadius: "50px" }}
              variant="contained"
              color="primary"
              onClick={() => {
                Swal.fire({
                  title: "Etes-vous sûr?",
                  text: `Voulez-vous generer un nouveau code?`,
                  icon: "warning",
                  showCancelButton: true,
                  confirmButtonColor: "#3085d6",
                  cancelButtonColor: "#d33",
                  confirmButtonText: "Oui, générer-le!",
                  cancelButtonText: "Non, Annulez-le!",
                }).then(async (result) => {
                  if (result.isConfirmed) {
                   await generateCode().then((res) => {
                    setPosted(false);
                    
                    Swal.fire({
                      title: "Succès!",
                      text: `${res.data.message}`,
                      icon: "success",
                      confirmButtonText: "OK",
                    }).then(() => {
                      window.location.reload();
                    });
                  });
                   
                  }
                });
              }}
            >
              {posted ? "En cours..." : "Générer du code"}
            </Button>
        <Button
          style={{
            borderRadius: "50px",
          }}
          variant="contained"
          color="success"
        >
          <CSVLink
            data={data ? data : []}
            headers={headers}
            filename="liste_code_activation_confidence.csv"
            style={{
              textDecoration: "none",
              color: "#fff",
            }}
          >
            Télécharger la liste du code en csv{" "}
          </CSVLink>
        </Button>
      </div>

      <MaterialReactTable
        columns={columns}
        data={data ?? []}
        state={{ isLoading: isLoadingCode }}
        enableRowSelection
        enableRowActions
        positionActionsColumn="last"
        renderRowActions={({ row }) => (
          <div>
            <Button
              style={{ marginRight: "1rem", borderRadius: "50px" }}
              variant="contained"
              color="error"
              onClick={() => {
                Swal.fire({
                  title: "Etes-vous sûr?",
                  text: `Voulez-vous vraiment supprimer le code ${row.original.code} ?`,
                  icon: "warning",
                  showCancelButton: true,
                  confirmButtonColor: "#3085d6",
                  cancelButtonColor: "#d33",
                  confirmButtonText: "Oui, supprimez-le!",
                  cancelButtonText: "Non, Annulez-le!",
                }).then(async (result) => {
                  if (result.isConfirmed) {
                    setIsDeleted(true);
                    await deleteCode(row.original.id).then((d) => {
                      setIsDeleted(false);
                      Swal.fire(
                        "Supprimé!",
                        `Le code ${row.original.code} a été supprimé avec succès`,
                        "success"
                      ).then(() => {
                        setData((data) => {
                          return data.filter(
                            (item) => item.id !== row.original.id
                          );
                        });
                      });
                    });
                  }
                });
              }}
            >
              {isDeleted ? "Suppression..." : "Supprimer"}
            </Button>
          </div>
        )}
      />
      {showModal && (
        <AddCode showModal={showModal} setShowModal={setShowModal} />
      )}
    </>
  );
};

export default ListCode;
