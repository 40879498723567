import Swal from "sweetalert2";
import React, { useState } from "react";
import Form from "react-bootstrap/Form";
import Modal from "react-bootstrap/Modal";
import Button from "react-bootstrap/Button";
import "react-toastify/dist/ReactToastify.css";
import { transformDate } from "../utils/service/extras";
import CircularProgress from "@mui/material/CircularProgress";
import { useFetchOneUser } from "../utils/hooks/use_fetch_one_user";
import { updateConsultationMedical } from "../utils/service/reservation_bilan";

export default function ApprouverConsultationMedical({
  showModal,
  setShowModal,
  modalData,
}) {
  const onHide = () => setShowModal(false);
  const [posted, setPosted] = useState(false);
  const [calendrier, setCalendrier] = useState("");

  const { user, isLoadingUser, errorUser } = useFetchOneUser(modalData.userId);

  const handleSubmit = (e) => {
    e.preventDefault();
    setPosted(true);
    console.log("calendrier", calendrier);
    updateConsultationMedical(
      {
        admin: true,
        info_user: {
          email: user.email,
          nom: user.first_name + " " + user.last_name,
          sexe: user.sexe,
        },
        confirm: true,
        date_confirm: calendrier,
        confirm_at: new Date().toISOString(),
      },
      modalData.id
    )
      .then((result) => {
        console.log(result);
        setPosted(false);
        onHide();
        Swal.fire({
          icon: "success",
          title: "Succès",
          text: `${result.data.message}`,
          confirmButtonText: "OK",
        }).then((result) => {
          if (result.isConfirmed) {
            window.location.reload();
          }
        });
      })
      .catch((error) => {
        setPosted(false);
        onHide();
        Swal.fire({
          icon: "error",
          title: "Oops...",
          text: `Une erreur s'est produite : ${error.response.data.error}`,
          confirmButtonText: "OK",
        });
      });
  };

  return (
    <>
      {errorUser ? (
        <div>Une erreur est survenue</div>
      ) : (
        <Modal
          show={showModal}
          onHide={onHide}
          size="lg"
          style={{
            backgroundColor: "rgba(0,0,0,0.5)",
            margin: "50px",
          }}
          backdrop="static"
          keyboard={false}
        >
          {isLoadingUser ? (
            <div
              style={{
                display: "flex",
                justifyContent: "center",
                alignItems: "center",
                height: "100%",
                width: "100%",
              }}
            >
              <CircularProgress />
            </div>
          ) : (
            <>
              <Modal.Header closeButton>
                <Modal.Title
                  style={{
                    fontSize: "1.5rem",
                    fontWeight: "bold",
                    color: "#444444",
                    textAlign: "center",
                  }}
                >
                  {" "}
                  Approuver la résérvation du consultation médical n°{" "}
                  {modalData.id}{" "}
                </Modal.Title>
              </Modal.Header>
              <Modal.Body>
                <Form onSubmit={handleSubmit}>
                  <Form.Label>
                    Choisir la date du rendez-vous du patient
                  </Form.Label>
                  {modalData.dates &&
                    modalData.dates.map((date) => (
                      <Form.Check
                        type="radio"
                        name="date"
                        value={date}
                        label={transformDate(date)}
                        onChange={(e) => {
                          setCalendrier(e.target.value);
                        }}
                      />
                    ))}

                  <Modal.Footer>
                    <Button
                      variant="danger"
                      onClick={onHide}
                      style={{
                        borderRadius: "50px",
                      }}
                    >
                      Fermer
                    </Button>
                    <Button
                      type="submit"
                      variant="success"
                      style={{
                        borderRadius: "50px",
                      }}
                    >
                      {posted ? "En cours..." : "Approuver"}
                    </Button>
                  </Modal.Footer>
                </Form>
              </Modal.Body>
            </>
          )}
        </Modal>
      )}
    </>
  );
}
