import { RouteAxios } from "../url_axios";
import { useState, useEffect } from "react";

export function useFetchAllCode() {
  const [code, setCode] = useState();
  const [isLoadingCode, setIsLoadingCode] = useState(false);
  const [errorCode, setErrorCode] = useState(false);

  useEffect(() => {
    async function fetchOnecode() {
      setIsLoadingCode(true);
      try {
        const response = await RouteAxios.get(`/code`, {
          headers: {
            "Content-Type": "application/json",
            Authorization: `Bearer ${localStorage.getItem("token")}`,
          },
        });
        setCode(response.data.data);
        setIsLoadingCode(false);
      } catch (e) {
        setErrorCode(true);
        console.error(e);
      } finally {
        setIsLoadingCode(false);
      }
    }
    fetchOnecode();
  }, []);
  return {
    code,
    isLoadingCode,
    errorCode,
  };
}
