import { RouteAxios } from "../url_axios";
import { useState, useEffect } from "react";

export function useFetchSupport() {
  const [dataSupport, setDataSupport] = useState();
  const [isLoadingSupport, setIsLoadingSupport] = useState(false);
  const [errorSupport, setErrorSupport] = useState(false);

  useEffect(() => {
    async function FetchSupport() {
      setIsLoadingSupport(true);
      try {
        const response = await RouteAxios.get(`/extras/support_admin`, {
          headers: {
            "Content-Type": "application/json",
            Authorization: `Bearer ${localStorage.getItem("token")}`,
          },
        });
        setDataSupport(response.data[0]);
        setIsLoadingSupport(false);
      } catch (e) {
        setErrorSupport(true);
        console.error(e);
      } finally {
        setIsLoadingSupport(false);
      }
    }
    FetchSupport();
  }, []);
  return {
    dataSupport,
    isLoadingSupport,
    errorSupport,
  };
}
