import * as Yup from "yup";
import * as React from "react";
import { useState } from "react";
import ToastTr from "./toastTr";
import Box from "@mui/material/Box";
import { toast } from "react-toastify";
import Avatar from "@mui/material/Avatar";
import Button from "@mui/material/Button";
import { useForm } from "react-hook-form";
import { useHistory } from "react-router-dom";
import "react-toastify/dist/ReactToastify.css";
import TextField from "@mui/material/TextField";
import Container from "@mui/material/Container";
import InputLabel from "@mui/material/InputLabel";
import Typography from "@mui/material/Typography";
import IconButton from "@mui/material/IconButton";
import { login } from "../utils/service/user_manage";
import FormControl from "@mui/material/FormControl";
import { yupResolver } from "@hookform/resolvers/yup";
import OutlinedInput from "@mui/material/OutlinedInput";
import Visibility from "@mui/icons-material/Visibility";
import InputAdornment from "@mui/material/InputAdornment";
import VisibilityOff from "@mui/icons-material/VisibilityOff";
import LockOutlinedIcon from "@mui/icons-material/LockOutlined";

export default function SignIn() {
  const [isSubmitting, setIsSubmitting] = useState(false);
  const [showPassword, setShowPassword] = React.useState(false);

  const handleClickShowPassword = () => setShowPassword((show) => !show);

  const handleMouseDownPassword = (event) => {
    event.preventDefault();
  };

  const history = useHistory();
  const login_ = async (data) => {
    console.log("data,", data);
    setIsSubmitting(true);
    await login(data)
      .then((res) => {
        console.log("res", res.status);
        if (res.status === 200) {
          localStorage.setItem("token", res.data.token);
          localStorage.setItem("user", data.username);
          history.push("/home");
          window.location.reload();
        } else {
          setIsSubmitting(false);
          toast.error(res.data.message);
        }
      })
      .catch((err) => {
        setIsSubmitting(false);
        toast.error(err.response.data.message);
      });
    setIsSubmitting(false);
  };

  const validationSchema = Yup.object().shape({
    matricule: Yup.string(),
    password: Yup.string(),
  });

  const { register, handleSubmit } = useForm({
    resolver: yupResolver(validationSchema),
  });

  return (
    <>
      <form onSubmit={handleSubmit(login_)}>
        <Container component="main" maxWidth="xs">
          <Box
            sx={{
              display: "flex",
              flexDirection: "column",
              alignItems: "center",
            }}
          >
            <Avatar sx={{ m: 1, bgcolor: "secondary.main" }}>
              <LockOutlinedIcon />
            </Avatar>
            <Typography component="h1" variant="h5">
              Authentification
            </Typography>
            <Box sx={{ mt: 1 }}>
              <TextField
                margin="normal"
                required
                fullWidth
                id="matricule"
                label="Nom d'utilisateur"
                name="username"
                autoFocus
                {...register("username")}
              />

              <FormControl sx={{ width: "50ch" }} variant="outlined">
                <InputLabel htmlFor="outlined-adornment-password">
                  Mot de passe
                </InputLabel>
                <OutlinedInput
                  name="password"
                  id="outlined-adornment-password"
                  type={showPassword ? "text" : "password"}
                  endAdornment={
                    <InputAdornment position="end">
                      <IconButton
                        aria-label="toggle password visibility"
                        onClick={handleClickShowPassword}
                        onMouseDown={handleMouseDownPassword}
                        edge="end"
                      >
                        {showPassword ? <VisibilityOff /> : <Visibility />}
                      </IconButton>
                    </InputAdornment>
                  }
                  label="Mot de passe"
                  {...register("password")}
                />
              </FormControl>

              <Button
                style={{
                  borderRadius: "50px",
                }}
                type="submit"
                fullWidth
                variant="contained"
                sx={{ mt: 3, mb: 2 }}
              >
                {isSubmitting ? "connexion en cours..." : "Connexion"}
              </Button>
            </Box>
          </Box>
        </Container>
      </form>
      <ToastTr />
    </>
  );
}
