import Modal from "react-bootstrap/Modal";

export default function ModalPassport({ data, showModal, setShowModal }) {
  return (
    <Modal
      show={showModal}
      onHide={() => setShowModal(false)}
      size="lg"
      style={{
        backgroundColor: "rgba(0,0,0,0.5)",
        marginTop: "4rem",
      }}
      backdrop="static"
      keyboard={false}
    >
      <Modal.Header closeButton>
        <Modal.Title
          style={{
            fontSize: "1.5rem",
            fontWeight: "bold",
            color: "#444444",
            textAlign: "center",
          }}
        >
          {" "}
          passeport de {data.fullName}
        </Modal.Title>
      </Modal.Header>
      <Modal.Body>
        <img src={data.passport} alt="passport" style={{ width: "100%" }} />
      </Modal.Body>
    </Modal>
  );
}
