import Swal from "sweetalert2";
import { v4 as uuidv4 } from "uuid";
import React, { useEffect, useState } from "react";
import Form from "react-bootstrap/Form";
import Modal from "react-bootstrap/Modal";
import Button from "react-bootstrap/Button";
import "react-toastify/dist/ReactToastify.css";
import TextField from "@mui/material/TextField";
import { addCode } from "../utils/service/code_manage";
import IconButton from "@material-ui/core/IconButton";
import AddCircleRoundedIcon from "@mui/icons-material/AddCircleRounded";
import RemoveCircleSharpIcon from "@mui/icons-material/RemoveCircleSharp";
import {
  Checkbox,
  InputLabel,
  ListItemIcon,
  ListItemText,
  MenuItem,
  Select,
} from "@mui/material";
import { FormControl } from "react-bootstrap";
import { makeStyles } from "@material-ui/core/styles";
import { getAllUserConfidence } from "../utils/service/user_manage";
import { sendMessage } from "../utils/service/message";
export default function AddMessage({ showModal, setShowModal }) {
  const onHide = () => setShowModal(false);
  const [posted, setPosted] = useState(false);
  const [isLoading, setIsLoading] = useState(false);
  const [data, setData] = useState([]);
  const [options, setOptions] = useState([]);
  useEffect(() => {
    setIsLoading(true);
    getAllUserConfidence()
      .then((res) => {
        console.log(res.data.users);
        let optionsData = res.data.users.map((user) => {
          return {
            id: user.id,
            fullname: `${user.first_name} ${user.last_name}`,
            email: user.email,
          };
        });
        setOptions(optionsData);
        console.log(options);
        setData(
          res.data.users.map((user) => {
            let userRow = {
              id: user.id,
              fullname: `${user.firstname} ${user.lastname}`,
            };
            return userRow;
          })
        );
      })
      .finally(() => setIsLoading(false));
  }, []);

  const [inputFields, setInputFields] = useState([
    {
      id: uuidv4(),
      titre: "",
      topic: "",
      contenu: "",
      userIds: [],
    },
  ]);
  const [userIds, setUserIds] = useState([]);
  const [selectedUsers, setSelectedUsers] = useState([]);
  const isAllSelected =
    options.length > 0 && selectedUsers.length === options.length;

  const handleChange = (event) => {
    const value = event.target.value;
    console.log("Value", value);
    if (value[value.length - 1] === "all") {
      setSelectedUsers(
        selectedUsers.length === options.length
          ? []
          : options.map((option) => option.id)
      );
      return;
    }
    setSelectedUsers(value);
    setInputFields({
      ...inputFields,
      userIds: value,
    });
  };

  const handleSubmit = (e) => {
    e.preventDefault();
    setPosted(true);
    console.log("InputFields", inputFields);

    sendMessage(inputFields).then((res) => {
      setPosted(false);
      onHide();
      Swal.fire({
        title: "Succès!",
        text: `${res.data.message}`,
        icon: "success",
        confirmButtonText: "OK",
      }).then(() => {
        window.location.reload();
      });
    });
  };
  /*
  const handleChangeInput = (id, event) => {
    const newInputFields = inputFields.map((i) => {
      if (id === i.id) {
        i[event.target.name] = event.target.value;
      }
      return i;
    });
    setInputFields(newInputFields);
  };*/

  const getSelectedUserRender = () => {
    let usersRender = options
      .filter((option) => selectedUsers.includes(option.id))
      .map((user) => user.fullname)
      .join(", ");
    console.log("usersRender", usersRender);
    return usersRender;
  };
  const handleAddFields = () => {
    setInputFields([
      ...inputFields,
      {
        id: uuidv4(),
        titre: "",
        topic: "",
        contenu: "",
      },
    ]);
  };

  const handleRemoveFields = (id) => {
    const values = [...inputFields];
    values.splice(
      values.findIndex((value) => value.id === id),
      1
    );
    setInputFields(values);
  };

  return (
    <Modal
      show={showModal}
      onHide={onHide}
      size="lg"
      style={{
        backgroundColor: "rgba(0,0,0,0.5)",
        margin: "50px",
      }}
      backdrop="static"
      keyboard={false}
    >
      <Modal.Header closeButton>
        <Modal.Title
          style={{
            fontSize: "1.5rem",
            fontWeight: "bold",
            color: "#444444",
            textAlign: "center",
          }}
        >
          {" "}
          Envoyer un message
        </Modal.Title>
      </Modal.Header>
      <Modal.Body>
        <Form onSubmit={handleSubmit}>
          {
            <div
              key={inputFields.id}
              style={{
                display: "flex",
                flexDirection: "column",
                gap: "16px",
                marginBottom: "10px",
              }}
            >
              <TextField
                fullWidth
                required
                name="Titre"
                label="Titre"
                variant="outlined"
                value={inputFields.titre}
                onChange={(event) => {
                  event.preventDefault();
                  console.log(event.target.value);
                  let newInputFields = {
                    ...inputFields,
                    titre: event.target.value,
                  };
                  setInputFields(newInputFields);
                }}
              />
              <TextField
                fullWidth
                required
                name="Contenu"
                label="Contenu"
                variant="outlined"
                value={inputFields.contenu}
                onChange={(event) => {
                  event.preventDefault();
                  console.log(event.target.value);
                  let newInputFields = {
                    ...inputFields,
                    contenu: event.target.value,
                  };
                  setInputFields(newInputFields);
                }}
              />
              <TextField
                fullWidth
                required
                name="Topic"
                label="topic"
                variant="outlined"
                value={inputFields.topic}
                onChange={(event) => {
                  event.preventDefault();
                  console.log(event.target.value);
                  let newInputFields = {
                    ...inputFields,
                    topic: event.target.value,
                  };
                  setInputFields(newInputFields);
                }}
              />
              <InputLabel id="mutiple-select-label">Users</InputLabel>
              <Select
                labelId="mutiple-select-label"
                multiple
                value={selectedUsers}
                onChange={handleChange}
                renderValue={getSelectedUserRender}
              >
                <MenuItem value="all">
                  <ListItemIcon>
                    <Checkbox
                      checked={isAllSelected}
                      indeterminate={
                        selectedUsers.length > 0 &&
                        selectedUsers.length < options.length
                      }
                    />
                  </ListItemIcon>
                  <ListItemText primary="Select All" />
                </MenuItem>

                {options.map((option) => (
                  <MenuItem
                    style={{ display: "flex" }}
                    key={option.id}
                    value={option.id}
                  >
                    <ListItemIcon>
                      <Checkbox
                        checked={selectedUsers.indexOf(option.id) > -1}
                      />
                    </ListItemIcon>
                    <ListItemText
                      primary={option.fullname + ` (${option.email})`}
                    />
                  </MenuItem>
                ))}
              </Select>
            </div>
          }
          <Modal.Footer>
            <Button
              variant="danger"
              onClick={onHide}
              style={{
                borderRadius: "50px",
              }}
            >
              Fermer
            </Button>
            <Button
              type="submit"
              variant="success"
              style={{
                borderRadius: "50px",
              }}
            >
              {posted ? "En cours..." : "Envoyer"}
            </Button>
          </Modal.Footer>
        </Form>
      </Modal.Body>
    </Modal>
  );
}
