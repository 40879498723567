import * as Yup from "yup";
import Swal from "sweetalert2";
import React, { useState } from "react";
import Form from "react-bootstrap/Form";
import { useForm } from "react-hook-form";
import Modal from "react-bootstrap/Modal";
import Button from "react-bootstrap/Button";
import { yupResolver } from "@hookform/resolvers/yup";
import { modifySupport } from "../utils/service/support_manage";

export default function ModalModifySupport({
  dataSupport,
  showModalModify,
  setShowModalModify,
}) {
  const handleClose = () => setShowModalModify(false);
  const [posted, setPosted] = useState(false);

  console.log("dataSupport", dataSupport?.id);

  const validationSchema = Yup.object().shape({
    full_name: Yup.string().notRequired(),
    fonction: Yup.string().notRequired(),
    email: Yup.string().notRequired(),
    phone: Yup.string().notRequired(),
    whatsapp: Yup.string().notRequired(),
  });

  const modifySupport_ = async (data) => {
    setPosted(true);
    await modifySupport({
      ...data,
      id: dataSupport?.id,
    })
      .then((res) => {
        setPosted(false);
        handleClose();
        Swal.fire({
          title: "Succès",
          text: "Support modifié avec succès",
          icon: "success",
          confirmButtonText: "OK",
        });
      })
      .catch((err) => {
        Swal.fire({
          icon: "error",
          title: "Oops...",
          text: `Une erreur est survenue! ${err.response.data.error}}`,
        });
      });
  };

  const { register, handleSubmit } = useForm({
    resolver: yupResolver(validationSchema),
  });
  return (
    <>
      <Modal
        show={showModalModify}
        onHide={handleClose}
        style={{
          marginTop: "5rem",
        }}
        backdrop="static"
        keyboard={false}
      >
        <Modal.Header closeButton>
          <Modal.Title
            style={{
              color: "#000",
              fontWeight: "bold",
            }}
          >
            Modification du support{" "}
          </Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <Form onSubmit={handleSubmit(modifySupport_)}>
            <Form.Group className="mb-3" controlId="exampleForm.ControlInput1">
              <Form.Label>Nom complet</Form.Label>
              <Form.Control
                type="text"
                name="full_name"
                defaultValue={dataSupport?.full_name}
                autoFocus
                {...register("full_name")}
              />
            </Form.Group>
            <Form.Group
              className="mb-3"
              controlId="exampleForm.ControlTextarea1"
            >
              <Form.Label>Fonction</Form.Label>
              <Form.Control
                type="text"
                name="fonction"
                defaultValue={dataSupport?.fonction}
                autoFocus
                {...register("fonction")}
              />
            </Form.Group>
            <Form.Group className="mb-3" controlId="exampleForm.ControlInput1">
              <Form.Label>Email</Form.Label>
              <Form.Control
                type="text"
                name="email"
                defaultValue={dataSupport?.email}
                autoFocus
                {...register("email")}
              />
            </Form.Group>
            <Form.Group
              className="mb-3"
              controlId="exampleForm.ControlTextarea1"
            >
              <Form.Label>Téléphone</Form.Label>
              <Form.Control
                type="text"
                name="phone"
                defaultValue={dataSupport?.phone}
                autoFocus
                {...register("phone")}
              />
            </Form.Group>
            <Form.Group
              className="mb-3"
              controlId="exampleForm.ControlTextarea1"
            >
              <Form.Label>Whatsapp</Form.Label>
              <Form.Control
                type="text"
                name="whatsapp"
                defaultValue={dataSupport?.whatsapp}
                autoFocus
                {...register("whatsapp")}
              />
            </Form.Group>
            <Button
              type="submit"
              style={{
                backgroundColor: "#1E90FF",
                border: "none",
                color: "#fff",
                width: "100%",
                borderRadius: "50px",
              }}
            >
              {posted ? "Modification en cours..." : "Modifier"}
            </Button>
          </Form>
        </Modal.Body>
      </Modal>
    </>
  );
}
