const { RouteAxios } = require("../url_axios");

function UpdateReservationBilan(data, id) {
  return RouteAxios.put("bilan/reservation_admin/" + id, data, {
    headers: {
      "Content-Type": "application/json",
      Authorization: `Bearer ${localStorage.getItem("token")}`,
    },
  });
}

function updateConsultationMedical(data, id) {
  return RouteAxios.put("consultation_medical/admin/" + id, data, {
    headers: {
      "Content-Type": "application/json",
      Authorization: `Bearer ${localStorage.getItem("token")}`,
    },
  });
}

export { UpdateReservationBilan, updateConsultationMedical };
