const { RouteAxios } = require("../url_axios");

function addBilan(data) {
  return RouteAxios.post("/bilan/", data, {
    headers: {
      "Content-Type": "application/json",
      Authorization: `Bearer ${localStorage.getItem("token")}`,
    },
  });
}

function deleteBilan(id) {
  return RouteAxios.delete("/bilan/" + id, {
    headers: {
      "Content-Type": "application/json",
      Authorization: `Bearer ${localStorage.getItem("token")}`,
    },
  });
}

function modifyBilan(data, id) {
  return RouteAxios.put("/bilan/" + id, data, {
    headers: {
      "Content-Type": "application/json",
      Authorization: `Bearer ${localStorage.getItem("token")}`,
    },
  });
}

export { addBilan, deleteBilan, modifyBilan };
