import { useState } from "react";
import { FaWhatsapp } from "react-icons/fa";
import EditIcon from "@material-ui/icons/Edit";
import { BsTelephoneFill } from "react-icons/bs";
import ModalModifySupport from "./modal_modify_support";
import CircularProgress from "@mui/material/CircularProgress";
import { useFetchSupport } from "../utils/hooks/useFetchSupport";
import { Container, Row, Col, Card, Button } from "react-bootstrap";

export default function Support() {
  const { dataSupport, isLoadingSupport } = useFetchSupport();
  const [showModalModify, setShowModalModify] = useState(false);

  return (
    <Container fluid className="vh-50" style={{ backgroundColor: "white" }}>
      {isLoadingSupport ? (
        <div
          style={{
            display: "flex",
            justifyContent: "center",
            alignItems: "center",
            height: "100%",
            width: "100%",
            marginTop: "10rem",
          }}
        >
          <CircularProgress size={100} />
        </div>
      ) : (
        <Row className="h-100 d-flex justify-content-center align-items-center">
          <Col md={12} xl={8}>
            <Card style={{ borderRadius: "15px" }}>
              <Card.Header
                style={{
                  backgroundColor: "#F5F5F5",
                  borderRadius: "15px 15px 0 0",
                  textAlign: "center",
                  display: "flex",
                  justifyContent: "space-between",
                }}
              >
                <h4
                  style={{
                    fontFamily: "Poppins, sans-serif",
                    fontWeight: "bold",
                    fontSize: "1.5rem",
                  }}
                  className="card-title"
                >
                  Support
                </h4>
                <Button
                  style={{
                    backgroundColor: "#F5F5F5",
                    border: "none",
                    color: "#000",
                    borderRadius: "50px",
                  }}
                  onClick={() => setShowModalModify(true)}
                >
                  <EditIcon />
                </Button>
              </Card.Header>
              <Card.Body className="text-center">
                <div className="mt-3 mb-4">
                  <img
                    src={
                      process.env.REACT_APP_BACKEND_URL +
                      "/" +
                      dataSupport?.photo.split("/").slice(3, 5).join("/")
                    }
                    className="rounded-circle img-fluid"
                    style={{ width: "100px" }}
                    alt="user avatar"
                  />
                </div>
                <h4
                  style={{
                    fontFamily: "Poppins, sans-serif",
                    fontWeight: "bold",
                    fontSize: "2.5rem",
                  }}
                  className="mb-2"
                >
                  {dataSupport?.full_name}
                </h4>
                <p className="text-muted mb-4">
                  {dataSupport?.fonction} <span className="mx-2">|</span>{" "}
                  <span className="mx-2">{dataSupport?.email}</span>
                </p>

                <div
                  style={{
                    display: "flex",
                    justifyContent: "center",
                  }}
                >
                  <Button
                    variant="primary"
                    className="btn-rounded btn-lg"
                    style={{
                      marginRight: "1rem",
                      display: "flex",
                      borderRadius: "50px",
                    }}
                  >
                    <BsTelephoneFill /> {dataSupport?.phone}
                  </Button>
                  <Button
                    variant="success"
                    className="btn-rounded btn-lg"
                    style={{
                      marginRight: "1rem",
                      alignItems: "center",
                      display: "flex",
                      borderRadius: "50px",
                    }}
                  >
                    <FaWhatsapp /> {dataSupport?.whatsapp}
                  </Button>
                </div>
              </Card.Body>
            </Card>
          </Col>
        </Row>
      )}
      {showModalModify && (
        <ModalModifySupport
          dataSupport={dataSupport}
          showModalModify={showModalModify}
          setShowModalModify={setShowModalModify}
        />
      )}
    </Container>
  );
}
