const { RouteAxios } = require("../url_axios");

function modifySupport(data) {
  console.log(data);
  return RouteAxios.put(
    "/extras/support/" + data.id,
    {
      full_name: data.full_name,
      fonction: data.fonction,
      email: data.email,
      phone: data.phone,
      whatsapp: data.whatsapp,
    },
    {
      headers: {
        "Content-Type": "application/json",
        Authorization: `Bearer ${localStorage.getItem("token")}`,
      },
    }
  );
}

export { modifySupport };
