import { useEffect, useState } from "react";
import {getAllMessages} from "./../service/message"
export function useFetchMessages(){
    const [dataMessages,setDataMessage]=useState();
    const [isLoadingMessages,setIsLoadingMessages]=useState(false);

    const [errorMessages,setErrorMessages]=useState(false);
    useEffect(()=>{
        async function FetchAllMessage(){
           setIsLoadingMessages(true);
      try{
        const response = await getAllMessages();
        setDataMessage(response.data.data);
        setIsLoadingMessages(false);

      }catch(e){
        setErrorMessages(true);
        console.error(e);
      }finally{
        setIsLoadingMessages(false);
      } 
        }
        FetchAllMessage()

    },[])
    return {
        dataMessages,
        isLoadingMessages,
        errorMessages
    }

}