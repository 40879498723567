import { RouteAxios } from "../url_axios";
import { useState, useEffect } from "react";

export function useFetchReservationMedical() {
  const [dataReservationMedical, setDataReservationMedical] = useState();
  const [isLoadingReservationMedical, setIsLoadingReservationMedical] =
    useState(false);
  const [errorReservationMedical, setErrorReservationMedical] = useState(false);

  useEffect(() => {
    async function FetchReservationMedical() {
      setIsLoadingReservationMedical(true);
      try {
        const response = await RouteAxios.get(`/consultation_medical`, {
          headers: {
            "Content-Type": "application/json",
            Authorization: `Bearer ${localStorage.getItem("token")}`,
          },
        });
        setDataReservationMedical(response.data);
        setIsLoadingReservationMedical(false);
      } catch (e) {
        setErrorReservationMedical(true);
        console.error(e);
      } finally {
        setIsLoadingReservationMedical(false);
      }
    }
    FetchReservationMedical();
  }, []);
  return {
    dataReservationMedical,
    isLoadingReservationMedical,
    errorReservationMedical,
  };
}
