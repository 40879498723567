const { RouteAxios } = require("../url_axios");

function addCode(data) {
  let emails = data.map((email) => email.email);
  console.log("emails", emails);
  return RouteAxios.post(
    "/code",
    {
      listEmail: emails,
    },
    {
      headers: {
        "Content-Type": "application/json",
        Authorization: `Bearer ${localStorage.getItem("token")}`,
      },
    }
  );
}
function generateCode() {
   return RouteAxios.post(
    "/code/getCode/",
    {
      headers: {
        "Content-Type": "application/json",
        Authorization: `Bearer ${localStorage.getItem("token")}`,
      },
    }
  )
  
}

function deleteCode(id) {
  return RouteAxios.delete(`/code/${id}`, {
    headers: {
      "Content-Type": "application/json",
      Authorization: `Bearer ${localStorage.getItem("token")}`,
    },
  });
}
export { addCode, deleteCode,generateCode };
