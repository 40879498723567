function transformDate(element) {
  let date = new Date(element);
  let options = {
    weekday: "long",
    year: "numeric",
    month: "long",
    day: "numeric",
  };

  return date.toLocaleDateString("fr-FR", options);
}

function transformFullDate(element) {
  let date = new Date(element);
  let options = {
    weekday: "long",
    year: "numeric",
    month: "long",
    day: "numeric",
    hour: "numeric",
    minute: "numeric",
  };

  return date.toLocaleDateString("fr-FR", options);
}

export { transformDate, transformFullDate };
