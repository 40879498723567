import Home from "./views/home";
import Login from "./views/login";
import "bootstrap/dist/css/bootstrap.css";
import "bootstrap/dist/css/bootstrap.min.css";
import PrivateRoute from "./utils/private_route";
import {
  BrowserRouter as Router,
  Route,
  Switch,
  Redirect,
} from "react-router-dom";

export default function App() {
  return (
    <Router>
      <Switch>
        <Route path="/" exact="true">
          {localStorage.getItem("token") ? <Redirect to="/home" /> : <Login />}
        </Route>
        
        <PrivateRoute path="/home">
          <Home />
        </PrivateRoute>
      </Switch>
    </Router>
  );
}
