const { RouteAxios } = require("../url_axios");


export function sendMessage(data){
    return RouteAxios.post("/message/send_message", data, {
        headers: {
            "Content-Type": "application/json",
            Authorization: `Bearer ${localStorage.getItem("token")}`,
        },
    });
}
export function getAllMessages(){
    return RouteAxios.get("/message/all_messages", {
        headers: {
            "Content-Type": "application/json",
            Authorization: `Bearer ${localStorage.getItem("token")}`,
        },
    });
}