import React, { useMemo, useState } from "react";
import MaterialReactTable from "material-react-table";
import ApprouverReservationBilan from "./modal_approuver_bilan";
import { transformDate, transformFullDate } from "../utils/service/extras";
import { useFetchReservationBilan } from "../utils/hooks/use_fetch_reservation_bilan";

// import Component
import Modal from "./modal";

//Material-UI Imports

import { Box, Button } from "@mui/material";

function choixDate(dates) {
  if (!dates) {
    return [];
  }
  return dates.map((date) => transformDate(date));
}

function AnnuleConfirme(element) {
  if (element.cancel && element.cancel_at !== undefined) {
    return `Annulé__le ${transformDate(element.cancel_at)}`;
  } else if (element.confirm && element.confirm_at !== undefined) {
    return `Confirmé__le ${transformDate(element.confirm_at)}`;
  }
  return "En attente";
}

const ReservationBilan = () => {
  const [openModal, setOpenModal] = useState(false);
  const [modalData, setModalData] = useState("");

  const [openModalApprouver, setOpenModalApprouver] = useState(false);
  const [modalDataApprouver, setModalDataApprouver] = useState(null);

  let data = [];
  const {
    dataReservationBilan,
    isLoadingReservationBilan,
    errorReservationBilan,
  } = useFetchReservationBilan();

  if (
    !isLoadingReservationBilan &&
    !errorReservationBilan &&
    dataReservationBilan
  ) {
    data = dataReservationBilan;
  }

  //should be memoized or stable
  const columns = useMemo(
    () => [
      {
        accessorKey: "id",
        header: "Numéro de la réservation",
      },
      {
        accessorFn: (row) => transformFullDate(row.created_at),
        header: "Date de post",
      },
      {
        accessorFn: (row) => choixDate(row.calendrier),
        header: "Choix de date du propriétaire",
        Cell: ({ cell }) => (
          <ul>
            {cell.getValue().map((date, index) => (
              <li key={index}>{date}</li>
            ))}
          </ul>
        ),
      },
      {
        accessorFn: (row) =>
          AnnuleConfirme({
            cancel: row.cancel,
            cancel_at: row.cancel_at,
            confirm: row.confirm,
            confirm_at: row.confirm_at,
          }),
        header: "status",
        Cell: ({ cell }) => (
          <>
            <Box
              component="span"
              sx={(theme) => ({
                backgroundColor:
                  cell.getValue().split("__")[0] === "Annulé"
                    ? theme.palette.error.dark
                    : cell.getValue().split("__")[0] === "Confirmé"
                    ? theme.palette.success.dark
                    : theme.palette.warning.light,

                borderRadius: "0.25rem",
                color: "#fff",
                maxWidth: "9ch",
                p: "0.25rem",
              })}
            >
              {cell.getValue().split("__")[0] === "Annulé"
                ? cell.getValue().split("__")[0] +
                  " " +
                  cell.getValue().split("__")[1]
                : cell.getValue().split("__")[0] === "Confirmé"
                ? cell.getValue().split("__")[0] +
                  " " +
                  cell.getValue().split("__")[1]
                : cell.getValue()}
            </Box>
          </>
        ),
      },
      {
        accessorFn: (row) => {
          if (row.confirm === true) {
            return transformFullDate(row.date_confirm);
          } else {
            return "";
          }
        },
        header: "Date du rdv confirmé",
      },
    ],
    []
  );

  return (
    <>
      <h1
        style={{
          textAlign: "center",
          marginTop: "2rem",
          marginBottom: "2rem",
          fontFamily: "Roboto",
        }}
      >
        {" "}
        Liste de la réservation du Bilan
      </h1>
      <MaterialReactTable
        columns={columns}
        data={data ?? []}
        state={{ isLoading: isLoadingReservationBilan }}
        enableRowSelection
        enableRowActions
        positionActionsColumn="last"
        renderRowActions={({ row }) => (
          <div
            style={{
              display: "flex",
              justifyContent: "space-around",
            }}
          >
            <Button
              style={{ marginRight: "1rem", borderRadius: "50px" }}
              variant="contained"
              onClick={() => {
                setOpenModal(true);
                setModalData(row.original);
              }}
            >
              Voir Details
            </Button>

            <Button
              style={{ marginRight: "1rem", borderRadius: "50px" }}
              variant="contained"
              color="success"
              disabled={
                true
                  ? row.original.confirm === true || row.original.cancel
                  : false
              }
              onClick={() => {
                setOpenModalApprouver(true);
                setModalDataApprouver(row.original);
              }}
            >
              Approuver
            </Button>
          </div>
        )}
      />
      {openModal && (
        <Modal
          open={openModal}
          setOpenModal={setOpenModal}
          modalData={modalData}
        />
      )}
      {openModalApprouver && (
        <ApprouverReservationBilan
          showModal={openModalApprouver}
          setShowModal={setOpenModalApprouver}
          modalData={modalDataApprouver}
        />
      )}
    </>
  );
};

export default ReservationBilan;
