const { RouteAxios } = require("../url_axios");

function login(data) {
  return RouteAxios.post("/admin/login", data);
}

function getAllUserConfidence() {
  return RouteAxios.get("/user/get_all_users", {
    headers: {
      "Content-Type": "application/json",
      Authorization: `Bearer ${localStorage.getItem("token")}`,
    },
  });
}

export { login, getAllUserConfidence };
