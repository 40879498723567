import React from "react";
import Box from "@mui/material/Box";
import Modal from "react-bootstrap/Modal";
import CircularProgress from "@mui/material/CircularProgress";
import { useFetchOneUser } from "../utils/hooks/use_fetch_one_user";
import { Grid, Paper, Typography, makeStyles } from "@material-ui/core";

const useStyles = makeStyles((theme) => ({
  modal: {
    display: "flex",
    alignItems: "center",
    justifyContent: "center",
  },
  paper: {
    padding: theme.spacing(3),
    backgroundColor: "#F7F7F7",
    borderRadius: "12px",
    boxShadow: "0px 3px 10px rgba(0, 0, 0, 0.2)",
  },
  title: {
    marginBottom: theme.spacing(2),
    color: "#444444",
    fontWeight: "bold",
    fontSize: "24px",
  },
  subtitle: {
    marginTop: theme.spacing(2),
    marginBottom: theme.spacing(2),
    color: "#777777",
    fontWeight: "bold",
    fontSize: "18px",
  },
  label: {
    color: "#444444",
    fontWeight: "bold",
    marginBottom: theme.spacing(1),
  },
  value: {
    color: "#666666",
    marginBottom: theme.spacing(1),
  },
  image: {
    width: "100%",
    height: "auto",
  },
  closeButton: {
    position: "absolute",
    top: "5px",
    right: "5px",
  },
}));

const DetailsModal = ({ modalData, setOpenModal, open }) => {
  const classes = useStyles();

  //  Fetch data user
  const { user, isLoadingUser } = useFetchOneUser(modalData.userId);

  //  Manage modal
  const handleClose = () => setOpenModal(false);

  //  Transform date
  function transformDate(element) {
    let date = new Date(element);
    let options = {
      weekday: "long",
      year: "numeric",
      month: "long",
      day: "numeric",
      hour: "numeric",
      minute: "numeric",
    };

    return date.toLocaleDateString("fr-FR", options);
  }

  return (
    <>
      <Modal
        show={open}
        onHide={handleClose}
        backdrop="static"
        keyboard={false}
        size="xl"
        style={{
          marginTop: "5rem",
          height: "150%",
        }}
      >
        <Modal.Header closeButton>
          <Modal.Title
            style={{
              color: "#000",
              fontWeight: "bold",
            }}
          >
            Détails de la réservation{" "}
          </Modal.Title>
        </Modal.Header>
        <Modal.Body>
          {isLoadingUser ? (
            <div
              style={{
                display: "flex",
                justifyContent: "center",
                alignItems: "center",
                height: "100%",
              }}
            >
              <Box sx={{ display: "flex" }}>
                <CircularProgress />
              </Box>
            </div>
          ) : (
            <Grid
              container
              spacing={6}
              style={{
                marginLeft: "2rem",
              }}
            >
              <Grid item xs={12} md={6}>
                <Typography variant="h6" className={classes.title}>
                  Information concernant la réservation
                </Typography>
                <Typography className={classes.label}>
                  Date de réservation
                </Typography>
                <Typography variant="body1" className={classes.value}>
                  {transformDate(modalData.created_at)}
                </Typography>
                <Typography variant="body1" className={classes.label}>
                  Age
                </Typography>
                <Typography variant="body1" className={classes.value}>
                  {modalData.age} ans
                </Typography>
                <Typography variant="body1" className={classes.label}>
                  Problème de santé
                </Typography>
                <Typography variant="body1" className={classes.value}>
                  {modalData.sante.probleme ? "Oui" : "Non"}
                </Typography>
                {modalData.sante.probleme ? (
                  <>
                    {" "}
                    <Typography variant="body1" className={classes.label}>
                      Description du problème
                    </Typography>
                    <Typography variant="body1" className={classes.value}>
                      {modalData.sante.description}
                    </Typography>
                  </>
                ) : null}
                <Typography variant="body1" className={classes.label}>
                  Les dates prévus par le demande
                </Typography>
                <Typography variant="body1" className={classes.value}>
                  {modalData.calendrier.map((date) => (
                    <li key={date}>{transformDate(date)}</li>
                  ))}
                </Typography>
                {modalData.confirm && (
                  <>
                    <Typography variant="body1" className={classes.label}>
                      Date confirmé pour le rendez-vous :{" "}
                    </Typography>
                    <Typography variant="body1" className={classes.value}>
                      {transformDate(modalData.date_confirm)}
                    </Typography>
                  </>
                )}
                {modalData.cancel && (
                  <>
                    <Typography variant="body1" className={classes.label}>
                      Date d'annulation :{" "}
                    </Typography>
                    <Typography variant="body1" className={classes.value}>
                      {transformDate(modalData.cancel_at)}
                    </Typography>
                  </>
                )}
              </Grid>
              {user && (
                <>
                  <Grid item xs={12} md={6}>
                    <Typography variant="h6" className={classes.title}>
                      Information concernant la personne propriétaire de la
                      réservation
                    </Typography>
                    <Typography variant="body1" className={classes.label}>
                      Nom complet :
                    </Typography>
                    <Typography variant="body1" className={classes.value}>
                      {user.first_name} {user.last_name}
                    </Typography>

                    <Typography variant="body1" className={classes.label}>
                      Mail :
                    </Typography>
                    <Typography variant="body1" className={classes.value}>
                      {user.email}
                    </Typography>
                    <Typography variant="body1" className={classes.label}>
                      Sexe
                    </Typography>
                    <Typography variant="body1" className={classes.value}>
                      {user.sexe}
                    </Typography>
                    <Typography variant="body1" className={classes.label}>
                      Date of Birth
                    </Typography>
                    <Typography variant="body1" className={classes.value}>
                      {user.birth_date}
                    </Typography>
                    <Typography variant="body1" className={classes.label}>
                      Phone
                    </Typography>
                    <Typography variant="body1" className={classes.value}>
                      {user.phone}
                    </Typography>
                    <Typography variant="body1" className={classes.label}>
                      Residence et addresse
                    </Typography>
                    <Typography variant="body1" className={classes.value}>
                      {user.country_residence} {user.address}
                    </Typography>
                  </Grid>
                </>
              )}
            </Grid>
          )}
        </Modal.Body>
      </Modal>
    </>
  );
};

export default DetailsModal;
