import { RouteAxios } from "../url_axios";
import { useState, useEffect } from "react";

export function useFetchOneUser(user_) {
  const [user, setUser] = useState();
  const [isLoadingUser, setIsLoadingUser] = useState(false);
  const [errorUser, setErrorUser] = useState(false);

  useEffect(() => {
    async function fetchOneUser() {
      setIsLoadingUser(true);
      try {
        const response = await RouteAxios.get(
          `user/get_one_user_admin/${user_}`,
          {
            headers: {
              "Content-Type": "application/json",
              Authorization: `Bearer ${localStorage.getItem("token")}`,
            },
          }
        );
        setUser(response.data.data);
        setIsLoadingUser(false);
      } catch (e) {
        setErrorUser(true);
        console.error(e);
      } finally {
        setIsLoadingUser(false);
      }
    }
    fetchOneUser();
  }, [user_]);
  return {
    user,
    isLoadingUser,
    errorUser,
  };
}
