import { RouteAxios } from "../url_axios";
import { useState, useEffect } from "react";

export function useFetchBilan() {
  const [dataBilan, setDataBilan] = useState();
  const [isLoadingBilan, setIsLoadingBilan] = useState(false);
  const [errorBilan, setErrorBilan] = useState(false);

  useEffect(() => {
    async function FetchBilan() {
      setIsLoadingBilan(true);
      try {
        const response = await RouteAxios.get(`/bilan/all`, {
          headers: {
            "Content-Type": "application/json",
            Authorization: `Bearer ${localStorage.getItem("token")}`,
          },
        });
        setDataBilan(response.data.data);
        setIsLoadingBilan(false);
      } catch (e) {
        setErrorBilan(true);
        console.error(e);
      } finally {
        setIsLoadingBilan(false);
      }
    }
    FetchBilan();
  }, []);
  return {
    dataBilan,
    isLoadingBilan,
    errorBilan,
  };
}
