import Row from "react-bootstrap/Row";
import Col from "react-bootstrap/Col";
import Card from "react-bootstrap/Card";
import React, { useState, useEffect } from "react";
import { getStatistique } from "../utils/service/statistique";
import CircularProgress from "@mui/material/CircularProgress";

export default function Dashboard() {
  const [statistique, setStatistique] = useState([]);
  const [error, setError] = useState(false);
  const [loading, setLoading] = useState(true);

  useEffect(() => {
    getStatistique()
      .then((res) => {
        setStatistique(res.data);
        setLoading(false);
      })
      .catch((err) => {
        setLoading(false);
        setError(true);
      });
  }, []);

  return (
    <>
      {error ? (
        <h1>Erreur : {error} </h1>
      ) : (
        <div
          style={{
            display: "flex",
            flexDirection: "column",
            justifyContent: "center",
            alignItems: "center",
          }}
        >
          <Row>
            <Col sm={4}>
              <Card
                style={{
                  width: "100%",
                  height: "240%",
                  backgroundColor: "white",
                  border: "none",
                  boxShadow: "0 4px 8px 0 rgba(0, 0, 0, 0.2)",
                  borderRadius: "20px",
                }}
              >
                <Card.Body
                  style={{
                    display: "flex",
                    flexDirection: "column",
                    justifyContent: "center",
                    alignItems: "center",
                  }}
                >
                  <img
                    style={{ width: "30%" }}
                    src="https://static.vecteezy.com/ti/vecteur-libre/t1/19608843-logo-de-bilan-de-sante-medical-en-cours-d-execution-moderne-gratuit-vectoriel.jpg"
                    alt="logo"
                  />
                  <h4>Nombre de réservation bilan:</h4>
                  {loading ? (
                    <CircularProgress />
                  ) : (
                    <ul>
                      <li>Confirmé: {statistique.bilan.confirm}</li>
                      <li>Annulé : {statistique.bilan.cancel}</li>
                      <li>En attente : {statistique.bilan.standBy}</li>
                    </ul>
                  )}
                </Card.Body>
              </Card>
            </Col>
            <Col sm={4}>
              <Card
                style={{
                  width: "100%",
                  height: "240%",
                  backgroundColor: "white",
                  border: "none",
                  boxShadow: "0 4px 8px 0 rgba(0, 0, 0, 0.2)",
                  borderRadius: "20px",
                }}
              >
                <Card.Body
                  style={{
                    display: "flex",
                    flexDirection: "column",
                    justifyContent: "center",
                    alignItems: "center",
                  }}
                >
                  <img
                    style={{ width: "30%" }}
                    src="https://static.vecteezy.com/ti/vecteur-libre/p3/6240756-consultation-medicale-en-ligne-logo-template-design-vector-for-healthcare-vectoriel.jpg"
                    alt="logo"
                  />
                  <h4>Nombre de réservation de consultation:</h4>
                  {loading ? (
                    <CircularProgress />
                  ) : (
                    <ul>
                      <li>Confirmé: {statistique.consultation.confirm}</li>
                      <li>Annulé : {statistique.consultation.cancel}</li>
                      <li>En attente : {statistique.consultation.standBy}</li>
                    </ul>
                  )}
                </Card.Body>
              </Card>
            </Col>
            <Col sm={4}>
              <Card
                style={{
                  width: "100%",
                  height: "240%",
                  backgroundColor: "white",
                  border: "none",
                  boxShadow: "0 4px 8px 0 rgba(0, 0, 0, 0.2)",
                  borderRadius: "20px",
                }}
              >
                <Card.Body
                  style={{
                    display: "flex",
                    flexDirection: "column",
                    justifyContent: "center",
                    alignItems: "center",
                  }}
                >
                  <img
                    style={{ width: "30%" }}
                    src="https://static.vecteezy.com/system/resources/previews/000/439/863/original/vector-users-icon.jpg"
                    alt="logo"
                  />
                  <h4>Nombre d'utilisateur:</h4>
                  {loading ? <CircularProgress /> : <h1>{statistique.user}</h1>}
                </Card.Body>
              </Card>
            </Col>
          </Row>
        </div>
      )}
    </>
  );
}
